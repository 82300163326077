<template>
  <div class="pl-8 pr-8">
    <div class="mb-xl-margin-file-sign">
      <v-row class="mt-10">
        <v-col class="text-left" cols="6">
          <h1 class="reference__title">Detalle solicitud</h1>
        </v-col>
        <v-col class="text-left" cols="6">
          <h1 class="reference__title">Feedback</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card outlined class="reference__card px-8 mt-8" elevation="0">
            <v-row justify="center" class="mb-8">
              <v-col class="px-0" cols="12">
                <ProcessesDetails :dataProcess="dataProcess" />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card outlined class="reference__card px-8 mt-8" elevation="0">
            <v-row justify="center" class="mb-8">
              <v-col class="px-0" cols="12">
                <FeedbackDetails
                  :dataProcess="dataProcess"
                  :dataHistorys="dataHistorys"
                  :show="false"
                  :showInfo="true"
                  @dataFeedback="dataFeedback"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-hover v-slot="{ hover }">
            <v-btn
              @click="aceptDewelopment()"
              block
              rounded
              style="color: #fff"
              :style="{
                'background-color': hover ? '#324CA1' : '#466BE3',
              }"
              class="pa-xl-0 mt-10"
            >
              Aceptar Desarrollo
            </v-btn>
          </v-hover>
        </v-col>
      </v-row>
    </div>
    <Alert
      :open="message.dialog"
      :text="message.sms"
      :title="message.title"
      :type="message.type"
      :redirect="message.redirect"
      @close="message.dialog = false"
    >
    </Alert>
    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        Cargando...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import color from "@/mixins/color";
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
import { VueEditor } from "vue2-editor";
import ProcessesDetails from "../newDevelopment/ProcessesDetails.vue";
import FeedbackDetails from "../newDevelopment/FeedbackDetails.vue";
export default {
  components: {
    Alert,
    VueEditor,
    ProcessesDetails,
    FeedbackDetails,
  },
  mixins: [color],
  data() {
    return {
      loading: {
        table: false,
      },
      modal: {
        info: false,
      },
      message: {
        dialog: false,
        title: "",
        sms: "",
        type: "",
        redirect: "",
      },
      loadingAll: false,
      dataProcess: [],
      dataHistorys: [],
      currentUser: [],
    };
  },
  methods: {
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");

      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    dataDewelopment(data) {
      this.create(data);
    },
    create(data) {
      this.loadingAll = true;
      Api.newDevelopment()
        .createDevelopment(this.token, data)
        .then((res) => {
          this.message.dialog = true;
          this.message.sms = res.data.message;
          this.message.title = "Excelente";
          this.message.type = "success";
        })
        .catch((error) => {
          this.message.dialog = true;
          this.message.sms = "Hemos tenido problemas con tu solicitud";
          this.message.title = "¡Oh no!";
          this.message.type = "error";
        })
        .finally(() => {
          this.loadingAll = false;
          this.$root.$emit("procesoFinalizado");
        });
    },
    searchData() {
      this.dataProcess = JSON.parse(
        localStorage.getItem("infoDevelopmentProcess")
      );
    },
    dataFeedback(data) {
      let dataFeedback = {
        workorder_id: this.dataProcess.id,
        mensaje: data.feedbackTxt,
        user_id: this.currentUser.id,
        prioridad_id: this.dataProcess.prioridad_id,
        status_id: "5",
      };
      this.loadingAll = true;
      Api.newDevelopment()
        .createHistory(this.token, dataFeedback)
        .then((res) => {
          this.message.dialog = true;
          this.message.sms = res.data.message;
          this.message.title = "Excelente";
          this.message.type = "success";
        })
        .catch((error) => {
          this.message.dialog = true;
          this.message.sms = "Hemos tenido problemas con tu solicitud";
          this.message.title = "¡Oh no!";
          this.message.type = "error";
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    dataHistory() {
      let data = {
        order_id: this.dataProcess.id,
        user_id: this.currentUser.id,
      };
      Api.newDevelopment()
        .getHistory(this.token, data)
        .then((res) => {
          this.dataHistorys = res.data.data;
          console.log(this.dataHistorys);
        })
        .catch((error) => {})
        .finally(() => {});
    },
    getUser() {
      let currentUser = this.$store.getters.getUser;
      this.currentUser = currentUser;
    },
    aceptDewelopment(){
      let dataFeedback = {
        workorder_id: this.dataProcess.id,
        mensaje: "Se acepta la solicitud de Desarrollo",
        user_id: this.currentUser.id,
        prioridad_id: this.dataProcess.prioridad_id,
        status_id: "8",
      };
      this.loadingAll = true;
      Api.newDevelopment()
        .createHistory(this.token, dataFeedback)
        .then((res) => {
          this.message.dialog = true;
          this.message.sms = 'Solicitud de Desarrollo aceptada';
          this.message.title = "Excelente";
          this.message.type = "success";
        })
        .catch((error) => {
          this.message.dialog = true;
          this.message.sms = "Hemos tenido problemas con tu solicitud";
          this.message.title = "¡Oh no!";
          this.message.type = "error";
        })
        .finally(() => {
          this.goList();
          this.loadingAll = false;
        });
    },
    goList() {
      this.$router.push({
        name: "managerProgressDevelopment"
      });
    },
  },
  created() {
    this.getToken();
    this.searchData();
    setTimeout(() => {
      this.getUser();
      this.dataHistory();
    }, 3000);
  },
};
</script>
<style>
@import "./../../assets/css/main.less";

.navidad-text {
  display: flex;
}

.reference__table .v-data-table-header {
  font-family: "RobotoRegular";
  background-color: #f2f4ff;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #353535 I !important;
}

tr,
th,
span .reference__table .text-start .sortable {
  font-size: 16px !important;
}

.reference__card {
  border-radius: 15px !important;
}

.reference__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.references__header {
  color: #353535 !important;
}
</style>
